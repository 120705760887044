.small-article-section-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
}
.small-article-mobile-link {
  margin-bottom: 28px;
}
.small-article-mobile-link:last-of-type {
  margin: 0;
}
.small-article-mobile {
  display: flex;
  max-width: 100vw;
  height: 40vw;
}
.small-article-mobile > img {
  width: 150px;
  height: 150px;
}

.small-article-text-mobile {
  margin-left: 10px;
  width: calc(100vw - 160px);
}

.small-article-text-mobile > h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
  margin: -1px 0 14px 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.small-article-text-mobile > p {
  font-size: 16px;
  font-weight: normal;
  line-height: 155%;
  margin: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

@media screen and (max-width: 370px) {
  .small-article-section-mobile {
    margin-bottom: 25px;
  }
  .small-article-mobile {
    height: 33vw;
    margin-bottom: 10px;
  }
  .small-article-mobile > img {
    width: 33vw;
    height: 33vw;
  }
  .small-article-text-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33vw;
  }
  .small-article-text-mobile > h2 {
    margin: 0;
  }
  .small-article-text-mobile > p {
    display: none;
  }
}
