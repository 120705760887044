.app {
  width: 85vw;
  margin: 6.25vh auto;
}
.btn-primary {
  background-color: white;
  color: #0d6efd;
}

.btn-secondary {
  background-color: white;
  color: #6c757d;
}
.btn-danger {
  background-color: white;
  color: #dc3545;
}
