.side-shape {
  position: absolute;
  top: 80vh;
  right: 0;
}
.top-triangle {
  width: 20vw;
  height: 600px;
  background: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0) 50%,
    #bd2f27 50%
  );
}
.bottom-triangle {
  width: 20vw;
  height: 600px;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 50%, #bd2f27 50%);
}
.article-detailed {
  max-width: 700px;
}

.article-detailed > h2 {
  font-size: 56px;
  font-weight: bold;
}
.article-detailed > h3 {
  font-size: 28px;
  font-weight: normal;
}
.article-detailed > figure {
  margin: 0 0 30px 0;
}
.article-detailed > figure > img {
  max-width: 700px;
}
.article-detailed > figure > figcaption {
  font-style: italic;
  margin-top: 5px;
}
.social-icons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  margin: 50px 0;
}
.social-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 5px;
  border: none;
  background-color: inherit;
}
.social-icon > svg {
  width: 70%;
  height: 70%;
}
.social-icon:hover {
  background-color: #bd2f27;
}
.social-icon:hover > svg {
  fill: white;
}
.no-bg:hover {
  background-color: transparent;
}
.no-bg:hover > svg {
  fill: green;
}
.article-detailed > p {
  margin: 0;
  line-height: 180%;
}
.article-detailed > .italic {
  font-style: italic;
  margin: 35px 0 0 0;
}
.article-detailed > h4 {
  margin: 35px 0 0 0;
  line-height: 180%;
}

.ad-container-article {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 35px 0;
}
.ad-container-article > img {
  width: 70%;
  margin: 0 auto;
}
.ad-container-article > p {
  width: 70%;
  margin: 0 auto;
  font-size: 16px;
  color: #5a5a5a;
}
.author-section {
  width: 33%;
  margin-top: 100px;
}
.author-section > img {
  width: 100%;
}
.author-section > p {
  margin-top: 5px;
}

@media screen and (max-width: 1050px) {
  .article-detailed {
    max-width: 500px;
  }
  .article-detailed > figure > img {
    max-width: 500px;
  }
}

@media screen and (max-width: 750px) {
  .article-detailed {
    max-width: 400px;
  }
  .article-detailed > figure > img {
    max-width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .social-icon {
    width: 80px;
    height: 80px;
  }
  .article-detailed {
    width: 100vw;
    max-width: 100vw;
  }
  .article-detailed > h2 {
    font-size: 45px;
    margin-left: 10px;
  }
  .article-detailed > h3 {
    font-size: 24px;
    margin-left: 10px;
  }
  .article-detailed > figure > img {
    width: 100vw;
    max-width: 100vw;
  }
  .article-detailed > figure > figcaption {
    margin-left: 10px;
  }
  .social-icons {
    margin-top: 20px;
  }
  .article-detailed > p {
    margin-left: 10px;
  }
  .article-detailed > h4 {
    margin-left: 10px;
  }
  .ad-container-article > img {
    width: 90%;
  }
  .side-shape {
    display: none;
  }
  .author-section {
    margin-top: 50px;
    margin-left: 10px;
  }
}
