.newsletter-tile-box {
  display: flex;
  flex-direction: column;
  width: 510px;
  height: 177px;
  margin-bottom: 80px;
}
.newsletter-title {
  font-size: 32px;
  font-weight: bold;
  margin: -1px 0 14px 0;
  color: #bd2f27;
}
