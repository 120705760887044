.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-fieldset {
  margin: 75px 0;
}
.form-author-section {
  display: flex;
}

.author-dropdown {
  width: 50%;
}

.add-new-author {
  height: 38px;
  margin-left: 15px;
}

.info-italic {
  font-style: italic;
}

.image-preview {
  display: flex;
  flex-direction: column;
}
.image-preview > img {
  width: 177px;
}
