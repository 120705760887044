@font-face {
  font-family: Abril;
  src: url(fonts/Abril/Abril_Text_Regular.otf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Abril;
  src: url(fonts/Abril/Abril_Text_Italic.otf);
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: Abril;
  src: url(fonts/Abril/Abril_Text_Bold.otf);
  font-weight: bold;
  font-style: normal;
}
* {
  box-sizing: border-box;
  color: #22211d;
}

body {
  font-family: Abril;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(254, 254, 253);
  overscroll-behavior: auto none;
}

a {
  text-decoration: none;
}

.hover-shadow {
  transition: transform 200ms;
}
.hover-shadow:hover {
  box-shadow: -5px 0 5px -5px rgb(145, 145, 145),
    -5px 5px 5px -5px rgb(145, 145, 145);
  transform: translateY(-2px);
}
