.additional-site-container {
  margin-left: 10px;
}
.credits-section > p > a:hover {
  color: blue;
  text-decoration: underline;
}

.additional-site-container > h2 {
  margin-top: 60px;
}
.additional-site-container > h2:first-child {
  margin-top: 0;
}
