.player-details {
  display: flex;
  align-items: center;
}
.player-bar {
  position: relative;
  background-color: black;
  width: 350px;
  height: 5px;
  border-radius: 20px;
  cursor: pointer;
}

.player-progress {
  position: absolute;
  top: -3.5px;
  left: 0;
  background-color: white;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid #0d6efd;
  cursor: pointer;
}
.player-time {
  margin: 0;
  margin-left: 5px;
}

.player-buttons {
  display: flex;
  justify-content: center;
}
.player-button {
  background-color: white;
  border: none;
}
.play-logo {
  width: 50px;
  height: 50px;
}
.pause-logo {
  width: 50px;
  height: 50px;
}
