.double-center {
  height: 87.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1 {
  margin-bottom: 24px;
}
.card {
  width: 18rem;
  padding: 22px;
}
