.header-triangle {
  width: 45vw;
  height: 30vh;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 50%, #bd2f27 50%);
}
.sg-logo {
  width: 6vw;
  position: absolute;
  top: 50px;
  right: 50px;
}

.logo-link {
  text-decoration: none;
}
.logo-link > h1 {
  color: #bd2f27;
  font-size: 10vw;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 50px;
  display: inline-block;
}
.logo {
  width: 30vw;
}

@media screen and (max-width: 920px) and (max-height: 550px) {
  .header-triangle {
    height: 40vh;
  }
  .sg-logo {
    top: 25px;
    right: 25px;
  }
}
@media screen and (max-width: 920px) and (min-height: 760px) {
  .header-triangle {
    height: 16vh;
  }
}
@media screen and (max-width: 720px) and (min-height: 500px) {
  .header-triangle {
    height: 20vh;
  }
} ;
