.footer-triangle {
  width: 100vw;
  height: 50vh;
  background: linear-gradient(to bottom right, white 50%, #bd2f27 50%);
}
.footer-rect {
  width: 100vw;
  height: 380px;
  background-color: #bd2f27;
  display: flex;
  justify-content: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1160px;
  width: 82vw;
}
.article-footer {
  width: 66vw;
}

.footer-link-list {
  font-size: 20px;
  margin: -22px 0 0 0;
  padding: 0;
  list-style: none;
  color: white;
}
.footer-link-list li {
  margin-bottom: 25px;
  transition: all 0.25s;
  cursor: pointer;
  color: white;
}
.footer-link-list li > a {
  color: white;
}
.footer-link-list li:hover,
.footer-link-list li a:hover {
  text-decoration: underline;
}
.sp-logo {
  height: 252px;
  width: 238px;
  color: white;
  margin-top: -42px;
}
