.footer-triangle-mobile {
  width: 100vw;
  height: 25vh;
  background: linear-gradient(to bottom right, white 50%, #bd2f27 50%);
}
.footer-rect-mobile {
  width: 100vw;
  height: 33vh;
  background-color: #bd2f27;
  display: flex;
  justify-content: center;
}

.footer-container-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 0 10px;
}

.footer-link-list-mobile {
  font-size: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
}
.footer-link-list-mobile > li {
  margin-bottom: 10px;
  cursor: pointer;
}
.footer-link-list-mobile > li > a {
  color: white;
}
.footer-link-list-mobile > li:last-of-type {
  margin-bottom: 0;
}
.footer-link-list-mobile > li:hover {
  text-decoration: underline;
}
.sp-logo-mobile {
  height: 33vw;
  width: 33vw;
  color: white;
  margin-top: -42px;
}
