.full-width-container {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  z-index: 1;
}

.lock-in-delete {
  position: relative;
  width: 50vw;
  background: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.lock-in-delete > button {
  color: #0d6efd;
  position: absolute;
  right: 25px;
  border: none;
  font-size: 50px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
}

.close-lock-in,
.lock-in-delete-text {
  margin: 75px 25px;
}

.lock-in-delete-text > h1 {
  font-size: 40px;
  text-align: left;
}
.lock-in-btns {
  margin-top: 25px;
}
.lock-in-btns > .delete {
  margin-right: 15px;
}
