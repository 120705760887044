.further-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.further-btn > button {
  padding: 18px 100px 14px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #bd2f27;
  color: #bd2f27;
  font-size: 38px;
  text-align: center;
  margin-bottom: -23px;
  transition: all 0.25s;
}
.further-btn > button:hover {
  color: white;
  background-color: #bd2f27;
}
