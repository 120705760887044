.article {
  display: flex;
  margin-bottom: 25px;
  transition: transform 200ms;
}
.article:hover {
  box-shadow: -5px 0 5px -5px rgb(145, 145, 145),
    -5px 5px 5px -5px rgb(145, 145, 145);
  transform: translateY(-2px);
}

.article > img {
  width: 100px;
  height: 100px;
}

.article-details {
  height: 100px;
  width: 80vw;
  margin-left: 10px;
}
.article-details > h2 {
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.article-details > h3 {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-right: 10px;
}
.button-container > button,
.button-container > a > button {
  font-size: 13px;
  width: 100px;
  margin: 5px 0;
}
