.log-out {
  display: flex;
  justify-content: flex-end;
}

.document-title {
  font-size: 60px;
  text-align: center;
  margin: 50px 0 75px 0;
}

.btn-container {
  display: flex;
  justify-content: center;
}
.add-article-container {
  margin-bottom: 50px;
}
.add-article-btn {
  width: 300px;
  height: 75px;
  font-size: 26px;
  padding: 0;
}

.ad-container {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-bottom: 50px;
}
.inserate-btn {
  width: 200px;
}
