.useless-class {
  background-color: green;
}

.article-type-1-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.article-type-1 {
  width: 353px;
  height: 353px;
  background-size: cover;
}

.title-type-1-cont {
  background-color: #fff9f9;
  position: relative;
  top: 67%;
}
.title-type-1 {
  font-size: 24px;
  font-weight: bold;
  padding: 2% 3%;
  height: 63px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1245px) {
  .article-type-1 {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 1080px) {
  .article-type-1 {
    width: 250px;
    height: 250px;
  }
  .title-type-1 {
    font-size: 20px;
    height: 55px;
  }
}

@media screen and (max-width: 910px) {
  .article-type-1 {
    width: 200px;
    height: 200px;
  }
  .title-type-1 {
    font-size: 18px;
    height: 50px;
  }
}

@media screen and (max-width: 715px) {
  .article-type-1 {
    width: 150px;
    height: 150px;
  }
  .title-type-1 {
    font-size: 16px;
    height: 40px;
  }
} ;
