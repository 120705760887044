.useless-class2 {
  background-color: blue;
}

.small-article-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}
.small-article {
  display: flex;
  width: 510px;
  height: 177px;
  margin-bottom: 80px;
}

.small-art-pic {
  width: 177px;
  height: 177px;
}
.small-art-text {
  margin-left: 20px;
}

.small-h2 {
  font-size: 26px;
  font-weight: bold;
  line-height: 100%;
  margin: -1px 0 14px 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.small-p {
  font-size: 18px;
  font-weight: 400;
  line-height: 155%;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1245px) {
  .small-article {
    width: 450px;
  }
}

@media screen and (max-width: 1100px) {
  .small-article {
    width: 400px;
  }
  .small-art-text {
    margin-left: 10px;
  }
  .small-h2 {
    font-size: 20px;
  }
  .small-p {
    font-size: 16px;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
  }
}

@media screen and (max-width: 975px) {
  .small-article {
    width: 350px;
    margin-bottom: 20px;
  }
  .small-art-pic {
    width: 140px;
    height: 140px;
  }
  .small-h2 {
    margin-bottom: 8px;
  }
  .small-p {
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
}
@media screen and (max-width: 860px) {
  .small-article {
    width: 100%;
    margin-bottom: 40px;
  }
  .small-art-pic {
    width: 170px;
    height: 170px;
  }
  .small-art-text {
    margin-left: 20px;
  }
  .small-h2 {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .small-p {
    font-size: 18px;
  }
}
