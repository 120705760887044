.podcast-box {
  display: flex;
  margin-bottom: 25px;
  transition: transform 200ms;
  display: flex;
  justify-content: space-between;
  padding: 15px 5px 0;
}
.podcast-box:hover {
  box-shadow: -5px 0 5px -5px rgb(145, 145, 145),
    -5px 5px 5px -5px rgb(145, 145, 145);
  transform: translateY(-2px);
}
