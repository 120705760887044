.big-article-section-mobile {
  margin-bottom: 50px;
}
.article-big {
  width: 100vw;
  height: 100vw;
  background-size: cover;
}

.article-big-title {
  background-color: #fff9f9;
  position: relative;
  top: 70%;
}
.article-big-title > h2 {
  padding: 2% 3%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

@media screen and (max-width: 370px) {
  .big-article-section-mobile {
    margin-bottom: 25px;
  }
  .article-big-title > h2 {
    font-size: 22px;
  }
}
