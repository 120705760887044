.further-btn-mobile {
  display: flex;
  justify-content: center;
}
.further-btn-mobile > button {
  padding: 14px 22px 10px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #bd2f27;
  color: #bd2f27;
  font-size: 18px;
  text-align: center;
  margin-bottom: -23px;
}
.further-btn-mobile > button:hover {
  color: white;
  background-color: #bd2f27;
}
