.big-article {
  display: flex;
  margin-bottom: 140px;
  max-height: 668px;
  height: 46.5vw;
  margin-bottom: 120px;
}

.big-art-pic {
  max-width: 668px;
  max-height: 668px;
  height: 46.5vw;
  width: 46.5vw;
}
.big-art-text {
  margin-left: 20px;
}

.big-h2 {
  font-size: 38px;
  font-weight: bold;
  line-height: 100%;
  margin: -4px 0 25px 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.big-h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 30px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 17; /* number of lines to show */
  line-clamp: 17;
  -webkit-box-orient: vertical;
}
.content > h4 {
  margin: 20px 0 5px 0;
}
.content > .italic {
  font-style: italic;
  margin: 20px 0 5px 0;
}
.big-p {
  font-size: 18px;
  font-weight: 400;
  line-height: 177%;
  margin: 0;
}

@media screen and (max-width: 1400px) {
  .content {
    -webkit-line-clamp: 11;
    line-clamp: 11;
  }
}

@media screen and (max-width: 1340px) {
  .content {
    -webkit-line-clamp: 10;
    line-clamp: 10;
  }
}
@media screen and (max-width: 1220px) {
  .big-h2 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  .content {
    -webkit-line-clamp: 8;
    line-clamp: 8;
  }
}
@media screen and (max-width: 1160px) {
  .big-h3 {
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }
  .content {
    -webkit-line-clamp: 6;
    line-clamp: 6;
  }
}

@media screen and (max-width: 1100px) {
  .content {
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }
}
@media screen and (max-width: 1050px) {
  .big-h3 {
    -webkit-line-clamp: 6;
    line-clamp: 6;
  }
  .content {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

@media screen and (max-width: 960px) {
  .big-h2 {
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }
  .big-h3 {
    -webkit-line-clamp: 7;
    line-clamp: 7;
  }
  .content {
    display: none;
  }
}

@media screen and (max-width: 860px) {
  .big-article {
    flex-direction: column-reverse;
    height: inherit;
    margin-bottom: 80px;
  }
  .big-art-pic-container {
    display: flex;
    justify-content: center;
  }
  .big-art-text {
    text-align: center;
    margin-left: 0;
  }
  .big-h2 {
    -webkit-line-clamp: none;
    line-clamp: none;
    margin-bottom: 0;
  }
  .big-h3 {
    -webkit-line-clamp: none;
    line-clamp: none;
    margin: 15px 0 10px 0;
  }
}
