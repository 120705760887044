.newsletter-input-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.newsletter-input-label {
  position: absolute;

  line-height: 1;
  left: 20px;
  top: 20px;
  background: white;
  transition: top 120ms ease-in, font-size 120ms ease-in;
  font-weight: bold;

  font-size: 24px;
  color: #bd2f27;
}
#newsletter-input:focus + .newsletter-input-label,
#newsletter-input:not(:placeholder-shown) + .newsletter-input-label {
  top: -8px;
  font-size: 18px;
}

#newsletter-input {
  border: 3px solid #bd2f27;
  height: 60px;
  width: 370px;
  font-size: 24px;

  padding-left: 20px;
  color: #bd2f27;
  position: relative;
  transition: border 0.1s ease-in-out;
}

#newsletter-input:focus {
  border: 4px solid #bd2f27;

  outline: none;
}
#newsletter-input:hover {
  border: 4px solid #bd2f27;
}

.newsletter-input-failure {
  background-color: #bd2f27;
  color: white !important;
}
.newsletter-input-success {
  border-color: #1e8449 !important;
  color: #1e8449 !important;
}

.newsletter-bottom-box {
  display: flex;
  width: 370px;
  justify-content: space-between;
  margin-top: 10px;
}
.newsletter-button {
  background-color: white;
  color: #bd2f27;
  border: 2px solid #bd2f27;
  width: 130px;
  transition: all 0.1s ease-in-out;

  padding: 2%;
}

.newsletter-button:hover {
  background-color: #bd2f27;
  color: white;
}

.newsletter-status-message {
  align-self: center;
  margin: 0;
  margin-left: 20px;
  color: #bd2f27;
}
