.App {
  display: flex;
  justify-content: center;
}
.App > div {
  max-width: 1160px;
  width: 82vw;
}

.desktop-loading-placeholder {
  font-size: 28px;
  font-style: bold;
}
