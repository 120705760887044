.header-triangle-mobile {
  width: 48vw;
  height: 20vh;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 50%, #bd2f27 50%);
}
.sg-logo-mobile {
  width: 12vw;
  position: absolute;
  top: 25px;
  right: 25px;
}
.logo-link-mobile {
  text-decoration: none;
}
.logo-link-mobile > h1 {
  color: #bd2f27;
  font-size: 22vw;
  margin: 20px 0 20px 10px;
  display: inline-block;
}
.links-logo-mobile {
  width: 50vw;
}

@media screen and (max-width: 370px) {
  .header-triangle-mobile {
    height: 18vh;
  }
  .sg-logo-mobile {
    top: 20px;
    right: 20px;
  }
  .logo-link-mobile > h1 {
    margin: 30px 0 0 10px;
  }
}
